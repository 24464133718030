import axios from 'axios';
//import { Gettoken } from '@/support/cache.js'
import Swal from 'sweetalert2'


axios.defaults.baseURL = process.env.VUE_APP_API_URL
// axios.interceptors.request.use(function (config) {
//   config.headers['X-Binarybox-Api-Key'] = process.env.VUE_APP_API_KEY;
//   config.headers['Content-Type'] = 'application/json';
//   return config;
// });


// 添加请求拦截器
axios.interceptors.request.use(function (config) {
  // 驗證toekn
  //const token = Gettoken();

  // if (token)
  //   console.log('添加 token: ' + token)
  // 统一添加请求头 Token
  // if (token) {
  //   config.headers['Authorization'] = 'Bearer ' + token
  // }
  // else {
  //   router.push({ name: "login" });
  // }


  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});


// Api回應統一異常處理
axios.interceptors.response.use(

  function (response) {

    let status = response.status;

    if (status == 200) {
      let code = response.data.code;

      switch (code) {
        case 200:
          Swal.fire({
            icon: 'success',
            title: response.data.message,
            showConfirmButton: false,
            timer: 1500
          });
          break;
      }
    }


    return response.data;
  },
  function (error) {


    let code = error.code;
    if (code == "ERR_BAD_REQUEST") {
   //   ClearStore();
   //   router.push({ name: "login" });
      return;
    }
    else {
      Swal.fire({
        icon: 'error',
        title: error.message,
        showConfirmButton: false,
        timer: 1500
      });
    }

    return Promise.reject(error);
  });


// get请求
export function apiGet(url, data = {}) {
  return axios.get(url, data,
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }

  );
}

// post请求
export function apiPost(url, data = {}) {
  return axios.post(url, data,
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }

  );
}

export function filepost(url, data = {}) {
  return axios.post(url, data,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  );

}
