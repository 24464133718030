import { apiGet, apiPost } from './service'

export async function listRevenue(data) {
	return await apiPost("/api/Revenue/list", data);
}

export async function fullListRevenue(data) {
	return await apiPost("/api/Revenue/fulllist", data);
}

export function getRevenue(data) {
	let url = "/api/Revenue/read/" + data;
	return apiGet(url, {params: {timestamp: Math.random()}});
}

export function postRevenue(data) {
	return apiPost("/api/Revenue/edit", data);
}

export function addRevenue(data) {
	return apiPost("/api/Revenue/add", data);
}

export function deleteRevenue(data) {
	return apiGet("/api/Revenue/delete/" + data);
}
