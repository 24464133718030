<template>
  <div class="main">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'LayoutDiv'
}
</script>

<style>
.main {
  background-color: #f7faff;
  min-height: 100vh;
  transition: margin-left .6s ease-out
}
</style>